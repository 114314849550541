import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import UnstyledToggleButton from '@material-ui/lab/ToggleButton';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  Stepper as UnstyledStepper,
  Button as UnstyledButton,
  Box as UnstyledBox,
  TextField,
} from '@material-ui/core';
import { DatePicker as UnstyledDatePicker } from '~/components/forms/fields/DatePicker.tsx';
import { P } from '~/components/BaseElements';

export const Box = styled(UnstyledBox).attrs({
  py: 8,
  mx: 3,
})``;

export const Container = styled.div`
  background-color: ${props => props.theme.palette.background.main};
  min-height: 800px;
  padding-top: 75px;
  ${breakpoint.md`
  padding-top: 90px;
  `}
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
`;

export const Subtitle = styled(P).attrs({
  variant: 'h3',
})`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 18px;
  line-height: 1.6rem;
  font-weight: 400;
  margin: 0 auto;
  ${breakpoint.md`
  max-width: 60%;
  `}
`;

export const Stepper = styled(UnstyledStepper)`
  background: ${props => alpha(props.theme.palette.common.white, 0.5)};
  border-radius: 6px;
`;

export const BackButton = styled(UnstyledButton)`
  background: transparent;
  color: ${props => props.theme.palette.primary.main};
  border: 1px solid ${props => props.theme.palette.tertiary.main};
  box-shadow: none;
  &:hover,
  &:focus {
    background: ${props => alpha(props.theme.palette.common.black, 0.15)};
    box-shadow: none;
  }
`;

export const NextButton = styled(UnstyledButton)`
  background: ${props => props.theme.palette.tertiary.main};
  color: ${props => props.theme.palette.common.white};

  &:hover,
  &:focus {
    text-decoration: none;
    background: ${props => props.theme.palette.tertiary.dark};
  }
`;

export const ResetButton = styled(UnstyledButton)`
  background: ${props => props.theme.palette.tertiary.main};
  color: ${props => props.theme.palette.common.white};
  &:hover,
  &:focus {
    background: ${props => props.theme.palette.tertiary.dark};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 30px;
`;

export const ToggleButton = styled(UnstyledToggleButton)`
  margin: ${props => props.theme.spacing(1)}px;
  border: 1px solid ${props => alpha(props.theme.palette.common.white, 0.5)} !important;
  border-radius: 4px !important;
  margin-left: 0px !important;
  background: ${props => alpha(props.theme.palette.common.white, 0.5)};
  transition: 0.2s;
  color: ${props => props.theme.palette.tertiary.main} !important;
  &.Mui-selected {
    background: ${props => props.theme.palette.common.white} !important;
    border: 1px solid ${props => props.theme.palette.tertiary.main} !important;
    font-weight: 500;
  }
`;

export const DatePicker = styled(UnstyledDatePicker)`
  .MuiOutlinedInput-adornedEnd {
    background-color: ${props => props.theme.palette.common.white};
  }
`;

export const TextArea = styled(TextField).attrs({
  rows: 3,
  multiline: true,
  fullWidth: true,
  variant: 'outlined',
})`
  textarea {
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.palette.text.secondary};
  }
`;
