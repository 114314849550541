import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import { Container, Title, Subtitle, Box } from '../styles';
import * as FormStyles from '~/components/forms/styles';
import { Grid, FormControlLabel } from '@material-ui/core';
import Checkbox from '~/components/BaseElements/inputs/Checkbox';
import { categories } from './constants';
import { formFields } from '~/components/forms/constants';
import { setSelectedFormValuesToTrue } from '~/common/generalUtils';
import { useGlobalState } from '~/context/global';
import { useUnwellReportInProgress } from '~/state/hooks/useUnwellReportInProgress.ts';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl.ts';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import { object, boolean, string } from 'yup';
import { navigate } from 'gatsby';
import {
  Temperature,
  useTemperatureValidation,
} from '~/components/forms/fields';

export const SymptomsSelector = ({ saveStepValuesToStore }) => {
  const { t } = useTranslation();
  const baseUrl = useLanguageUrl();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    saveHeadOfHouseholdStepValuesToStore,
    saveHouseholdMemberStepValuesToStore,
    getStepState,
  } = useUnwellReportInProgress();
  const { unwellReportInProgress } = useGlobalState().healthReport.household;

  const isHouseholdReport = !!unwellReportInProgress;
  const tPath = 'UnwellForm';
  const tPathContext = unwellReportInProgress?.memberId
    ? { context: 'householdMember', name: unwellReportInProgress.memberName }
    : null;

  const handleSaveStepValuesToStore = formattedFormValues => {
    if (isHouseholdReport) {
      if (unwellReportInProgress.memberId) {
        saveHouseholdMemberStepValuesToStore(
          formattedFormValues,
          unwellReportInProgress.memberId
        );
      } else {
        saveHeadOfHouseholdStepValuesToStore(formattedFormValues);
      }
    } else {
      saveStepValuesToStore(formattedFormValues);
    }
  };

  const stepState = getStepState('step-1');

  const [selectedSymptoms, setSelectedSymptoms] = useState(
    Object.keys(stepState?.symptoms ?? {})
  );

  const [temperatureUnit, setTemperatureUnit] = useState(
    stepState?.feverdetails?.[formFields.UNIT] || 'F'
  );
  const continueToNextStep = (formValues, recaptchaToken) => {
    const nextStepUrl = `${baseUrl}unwell/medical-background`;
    navigate(nextStepUrl, { state: { recaptchaToken, step1: formValues } });
  };

  const displaySnackbarRecaptchaError = () => {
    enqueueSnackbar(t('ErrorHandling.recaptcha'), {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    });
  };

  const submitForm = async values => {
    if (!executeRecaptcha) {
      return displaySnackbarRecaptchaError();
    }

    try {
      const recaptchaToken = await executeRecaptcha('unwell');
      const formattedValues = normalizeValues(values);
      handleSaveStepValuesToStore(formattedValues);
      continueToNextStep(formattedValues, recaptchaToken);
    } catch (error) {
      displaySnackbarRecaptchaError();
    }
  };

  const handleSymptoms = (event, newSymptoms) => {
    setSelectedSymptoms(newSymptoms);
  };

  const toggleTemperatureUnit = (e, setFieldValue) => {
    setTemperatureUnit(e.target.value);
    // Reset the temperature value when switching between temperature units.
    setFieldValue(formFields.TEMPERATURE, '');
  };

  const normalizeValues = values => {
    const feverdetails = { measured: !values[formFields.TEMPERATURE_CHECKED] };
    if (feverdetails.measured) {
      feverdetails.temperature = values[formFields.TEMPERATURE];
      feverdetails.unit = temperatureUnit;
    }
    return {
      symptoms: setSelectedFormValuesToTrue(selectedSymptoms),
      otherdetails: values[formFields.OTHER],
      feverdetails,
    };
  };

  let populatedValues = {
    [formFields.TEMPERATURE_CHECKED]: false,
    [formFields.TEMPERATURE]: '',
    [formFields.OTHER]: '',
  };
  if (!isEmpty(stepState?.symptoms ?? {})) {
    populatedValues = {
      ...populatedValues,
      [formFields.TEMPERATURE_CHECKED]:
        stepState?.feverdetails?.[formFields.TEMPERATURE_CHECKED] || false,
      [formFields.TEMPERATURE]:
        stepState?.feverdetails?.[formFields.TEMPERATURE] || '',
      [formFields.OTHER]: stepState?.[formFields.OTHER] || '',
    };
  }

  const validationSchema = object().shape({
    [formFields.TEMPERATURE_CHECKED]: boolean(),
    [formFields.TEMPERATURE]: useTemperatureValidation(
      formFields.TEMPERATURE_CHECKED,
      temperatureUnit,
      selectedSymptoms.includes('fever')
    ),
    [formFields.OTHER]: string().max(
      501,
      t('FormField.additionalComments.errors.maxCharacters')
    ),
  });

  useEffect(() => {
    setSelectedSymptoms(Object.keys(stepState?.symptoms ?? {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unwellReportInProgress]);

  const temperatureField = (values, setFieldValue) => (
    <FormStyles.Collapse in={selectedSymptoms.includes('fever')}>
      <FormStyles.NestedInputContainer container>
        <FormStyles.Label>{t(`${tPath}.highestTemperature`)}</FormStyles.Label>
        <Grid>
          <Temperature
            name={formFields.TEMPERATURE}
            toggleTemperatureUnit={e => toggleTemperatureUnit(e, setFieldValue)}
            temperatureUnit={temperatureUnit}
            disabled={values.measured}
          />
          <FormControlLabel
            label={t(`${tPath}.notMeasured`)}
            color="primary"
            control={
              <Checkbox
                color="primary"
                name={formFields.TEMPERATURE_CHECKED}
                checked={values.measured}
              />
            }
          />
        </Grid>
      </FormStyles.NestedInputContainer>
    </FormStyles.Collapse>
  );

  return (
    <Container>
      <Box>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Title>
            {t(`${tPath}.title`, {
              context: isHouseholdReport ? 'household' : null,
            })}
          </Title>
          <Subtitle>
            {t(`${tPath}.symptomsHeading`, { ...tPathContext })}
          </Subtitle>
          <Grid container xs={12} sm={10} justifyContent="center">
            <Formik
              onSubmit={submitForm}
              initialValues={populatedValues}
              validationSchema={validationSchema}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  {categories.map(({ name, symptoms }) => (
                    <React.Fragment key={name}>
                      <Styled.Category>
                        {t(`Symptoms.categories.${name}`)}
                      </Styled.Category>
                      <Styled.ButtonGroup
                        onChange={handleSymptoms}
                        value={selectedSymptoms}
                      >
                        {symptoms.map(({ value, icon }) => {
                          const label = t(`Symptoms.options.${value}`);
                          return (
                            <Styled.Button
                              key={value}
                              value={value}
                              data-test={`${value}_symptom_button`}
                            >
                              <Styled.Badge />
                              <Styled.Icon src={icon} alt={label} />
                              <p>{label}</p>
                            </Styled.Button>
                          );
                        })}
                      </Styled.ButtonGroup>
                      {name === 'wholebody' &&
                        temperatureField(values, setFieldValue)}
                    </React.Fragment>
                  ))}
                  <Styled.OtherContainer>
                    <FormStyles.Collapse
                      in={selectedSymptoms.includes('other')}
                    >
                      <FormStyles.NestedInputContainer container>
                        <FormStyles.Label>
                          {t(`${tPath}.otherDetails`, { ...tPathContext })}
                        </FormStyles.Label>
                        <Styled.OtherTextArea
                          id={formFields.OTHER}
                          name={formFields.OTHER}
                          placeholder={t(`${tPath}.additionalComments`)}
                        />
                      </FormStyles.NestedInputContainer>
                    </FormStyles.Collapse>
                  </Styled.OtherContainer>

                  <FormStyles.Divider />

                  <Styled.FormNavigation>
                    <FormStyles.SubmitButton variant="contained">
                      {t(`${tPath}.actions.next`)}
                    </FormStyles.SubmitButton>
                  </Styled.FormNavigation>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
